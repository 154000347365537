<div class="content" #messageContainer>
    <div>
        <h3 class="text-center pt-5"><b>Isha AI</b> Assistant</h3>
        <h4 class="my-5 text-center">How may I help you?</h4>
        <div class="d-flex">
            <!-- We do not need now since Azure AI Search takes care instead of neo4js
            <button type="button" class="btn btn-sec bg-gray-400" (click)="oneWorldXPrompt('What is OneWorldX')"> -->
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('What is OneWorldX')">                
                What is the OneWorldX?
            </button>
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('What is 5Es')">
                What is the 5Es framework?
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400" (click)="listAllXCardsPrompt('List all my Xcards')">
                List all my Xcards
            </button>
            <button type="button" class="btn btn-sec bg-gray-400" (click)="initiateCreateXCardPrompt('Create XCard')">
                Create Xcard
            </button>    
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('progress details about my tasks')">                
                Progress details about my Tasks
            </button>
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('progress details about my vision')">
                Progress details about my Vision
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('progress details about my true self')">                
                Progress details about my True Self
            </button>
            <button type="button" class="btn btn-sec bg-gray-400" (click)="sendMessage('What are my 3 recent goals')">
                What are my 3 recent goals?
            </button>
        </div>                
        <!-- <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                what is my achivement last week?
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me what is happening?
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                Create Xcard
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me about my schedule
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                what is my achivement last week?
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me what is happening?
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                Create Xcard
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me about my schedule
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                what is my achivement last week?
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me what is happening?
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                Create Xcard
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me about my schedule
            </button>
        </div>
        <div class="d-flex">
            <button type="button" class="btn btn-sec bg-gray-400">
                what is my achivement last week?
            </button>
            <button type="button" class="btn btn-sec bg-gray-400">
                Tell me what is happening?
            </button>
        </div> -->

    </div>
    <div *ngIf="showChat">
        <ng-container *ngFor="let message of currentChat.messages">
            <div class="flex flex-col space-y-4 p-5">
                <div class="flex items-end py-2" *ngIf="message.type == chatAiMessageType.Human">
                    <img src="{{message?.imageURL}}" alt="User Avatar" class="w-8 h-8 rounded-full mr-2">
                    <div class="bg-gray-300 p-3 rounded-lg">
                        <span class="text-red-200">{{this.senderName}}</span>
                        <p [innerText]="message.text"></p>
                    </div>
                </div>

                <div class="flex items-start py-2" *ngIf="message.type == chatAiMessageType.System">
                    <!-- <img src="{{message?.imageURL}}" alt="User Avatar" class="w-8 h-8 rounded-full mr-2">                   -->
                    <mat-icon>blur_circular</mat-icon>
                    <div class="bg-gray-300 p-3 rounded-lg">                        
                        <span class="text-red-200">Isha AI</span>
                        <p [innerText]="message.text"></p>
                    </div>
                </div>

            </div>
        </ng-container>
    </div>
    <owx-dots-loading-spinner *ngIf="loading"></owx-dots-loading-spinner>
</div>

<div class="r-footer-panel w-full">
    <!--  <input type="text" class="form-control" #text>
    <button (click)="sendMessage(text.value); text.value =''" class="border-none bg-blue-50">
        <mat-icon class="text-red-500 mx-2"> send</mat-icon>
    </button> -->
    <div class="footer-in shadow w-full">
        <div class="d-flex align-items-center">
            <textarea type="text" class="form-control" #text placeholder="Ask me anything..."></textarea>
            <!-- <mat-icon>keyboard_voice</mat-icon> -->
        </div>


        <div class="d-flex justify-content-between ms-2">
            <!-- <mat-icon>center_focus_strong</mat-icon> -->
            <!-- Krishna i kept empty icon here to have space between text area and send button -->
            <mat-icon></mat-icon>
            <div class="d-flex align-items-center">
                <span class="fs-12">0/2000</span>
                <mat-icon class="text-red-500 mx-2 pointer" (click)="sendMessage(text.value); text.value =''">
                    send</mat-icon>
            </div>
        </div>
    </div>
</div>